/* eslint-disable @typescript-eslint/naming-convention */

// Task automation keys are 1 to 1 match with those of on the mondayboard.
export enum ProjectTeamAssignmentTaskAutomationKeyEnum {
    ATTESTATION_COMPLETE = "ATTESTATION_COMPLETE",
    TRAINING_COMPLETE = "TRAINING_COMPLETE",
    SPONSOR_APPROVED_ONBOARDING_REQUEST = "SPONSOR_APPROVED_ONBOARDING_REQUEST",
    SEND_ONBOARDING_REQUEST_EMAIL = "SEND_ONBOARDING_REQUEST_EMAIL", 
    DEVICE_REQUIREMENT_COMPLETE = "DEVICE_REQUIREMENT_COMPLETE",
    CREATE_USER = "CREATE_USER",
    ADD_USER_TO_MFA_SETUP_AAD_GROUP = "ADD_USER_TO_MFA_SETUP_AAD_GROUP",
    SEND_MFA_SETUP_REQUEST_EMAIL = "SEND_MFA_SETUP_REQUEST_EMAIL",
    USER_HAS_CREATED_PASSWORD_AND_SETUP_MFA = "USER_HAS_CREATED_PASSWORD_AND_SETUP_MFA",
}

export enum UTypeEnum {
    db = "db",
    other = "other"
}

export interface IDeviceRequest {
    ContactNumber: string;
    PostalAddress: {
        StreetAddress: string;
        City: string;
        State: string;
        PostalCode: string;
        Country: string;
    };
}

export enum AttestationTargetTypeEnum {
    BreakingWaveV1 = "BreakingWave.v1",
    SynthixV1 = "Synthix.v1",
    GuestV1 = "Guests.v1",
}
