import React from "react";
import dayjs from "dayjs";
import TeamAccordion from "../../../../../../components/accordion/TeamAccordion";
import { wizardColours } from "../wizard.colours";
import StepIndicatorCircle from "../../../../../../components/step-indicator-circle";
import { IProjectTeamMemberDisplayItemsResponse } from "../../../../../../types/teamMemberDisplayItems";
import formatRateDisplay from "../../../../../../utils/formatRateDisplay";
import { getTeamName } from "../../../../../../utils/teams";
import useUserType from "../../../../../../hooks/useUserType";
import initialProjectAdminAssignmentStepsState from "./_state/initialState";
import { RevokeProjectAdminRightsWizardStepsContext, reducer } from "./_state/context";
import RevokeProjectAdminRightsWizardSteps from "./steps";
import * as S from "../wizard.styles";

interface IRevokeProjectAdminRightsWizardStepsProps {
    data: IProjectTeamMemberDisplayItemsResponse;
}

/**
 * ProjectAdminAssignmentWizard mode of <TeamWizards />.
 */
const RevokeProjectAdminRightsWizard: React.FC<IRevokeProjectAdminRightsWizardStepsProps> = ({
    data: teamMember
}) => {
    
    const [state, dispatch] = React.useReducer(reducer, initialProjectAdminAssignmentStepsState);

    const percentage = (): number => {
        const isAnsweredArray = Object.values(state)
            .filter((step) => step.order !== undefined)
            .map((step) => step.isStepAnswered);
        return (isAnsweredArray.filter((val) => val).length / isAnsweredArray.length) * 100;
    };

    const { userType } = useUserType(teamMember);

    return (
        <RevokeProjectAdminRightsWizardStepsContext.Provider value={{ state, dispatch }}>
            <TeamAccordion
                profileProgressCircleProps={{
                    size: 30,
                    percentage: percentage(),
                    percentagePathColour: wizardColours.memberIcon,
                    userType
                }}
                isToggleable={false}
                hasToggleIcon={false}
                defaultOpenState={true}
                homeProjectName={teamMember.HomeProjectName}
                teamMemberDisplayName={`${teamMember.FirstName} ${teamMember.LastName}`}
                team={getTeamName(teamMember.Team)}
                country={teamMember.Country}
                deviceType={teamMember.DeviceType}
                startDate={dayjs(teamMember.StartDate).format("DD MMM YYYY")}
                rate={`${formatRateDisplay(teamMember.Rate, teamMember.RateCurrency)}pcm`}
            >
                <S.TeamAccordionBody>
                    <S.StepIndicatorCirclesWrapper>
                        {Object.values(state)
                            .filter((step) => step.order !== undefined)
                            .sort((a, b) => a.order - b.order)
                            .map((step, index) => (
                                <StepIndicatorCircle
                                    key={index}
                                    value={index + 1}
                                    active={step.isStepActive}
                                    completed={!step.isStepActive && step.isStepAnswered}
                                    disabled={!step.isStepActive && !step.isStepAnswered}
                                    style={{ marginRight: 8 }}
                                />
                            ))}
                    </S.StepIndicatorCirclesWrapper>
                    <RevokeProjectAdminRightsWizardSteps />
                </S.TeamAccordionBody>
            </TeamAccordion>
        </RevokeProjectAdminRightsWizardStepsContext.Provider>
    );
};

export default RevokeProjectAdminRightsWizard;
