import { CreateJoinProjectDomainValidationFailureReasonEnum } from "../types/joinProject";

const joinProjectDomainValidationFailureReason = (bitwise: number): string => {

    if ((bitwise & CreateJoinProjectDomainValidationFailureReasonEnum.ProjectNotFound) === 
            CreateJoinProjectDomainValidationFailureReasonEnum.ProjectNotFound) {
        return "Project not found";
    }

    if ((bitwise & CreateJoinProjectDomainValidationFailureReasonEnum.SubscriptionTypeNone) === 
            CreateJoinProjectDomainValidationFailureReasonEnum.SubscriptionTypeNone) {
        return "User does not have a subscription";
    }    

    if ((bitwise & CreateJoinProjectDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) === 
            CreateJoinProjectDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) {
        return "You do not have permission to add this user";
    }   

    if ((bitwise & CreateJoinProjectDomainValidationFailureReasonEnum.ContactEmailDomainDoesNotMatchProjectDomain) === 
            CreateJoinProjectDomainValidationFailureReasonEnum.ContactEmailDomainDoesNotMatchProjectDomain) {
        return "Invalid email domain, does not match project domain";
    }      
    
    if ((bitwise & CreateJoinProjectDomainValidationFailureReasonEnum.InFlightManagedProcesses) === 
            CreateJoinProjectDomainValidationFailureReasonEnum.InFlightManagedProcesses) {
        return "User has existing managed processes";
    }  
    
    if ((bitwise & CreateJoinProjectDomainValidationFailureReasonEnum.AlreadyJoiningProject) === 
            CreateJoinProjectDomainValidationFailureReasonEnum.AlreadyJoiningProject) {
        return "User is already joining this project";
    }    
    
    if ((bitwise & CreateJoinProjectDomainValidationFailureReasonEnum.AlreadyMemberOfProject) === 
            CreateJoinProjectDomainValidationFailureReasonEnum.AlreadyMemberOfProject) {
        return "User is already a member of this project";        
    }        

    if ((bitwise & CreateJoinProjectDomainValidationFailureReasonEnum.HomeProjectNotFound) === 
            CreateJoinProjectDomainValidationFailureReasonEnum.HomeProjectNotFound) {
        return "Project not found";        
    }       

    return "Unknown error occurred";
};

export {
    joinProjectDomainValidationFailureReason
};
