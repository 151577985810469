const teams = {
    "clientoperations": "Client Operations",
    "design": "Design",
    "engineering": "Engineering",    
    "finance": "Finance",
    "operations": "Operations",
    "productionaccess": "Production Access"
} as const;

export const getTeamName = (team: string): string => {
    if (!team) {
        return "-";
    }

    const teamName = teams[team as keyof typeof teams];
    return teamName ?? team;
};

export default teams;
