import { JoinProjectMemberBlockerEnum } from "../types/joinProject";

const joinProjectMemberBlockerReason = (blocker: JoinProjectMemberBlockerEnum): string => {
    switch (blocker) {
        case JoinProjectMemberBlockerEnum.MemberOfCurrentProject: return "Already a member of this project";
        case JoinProjectMemberBlockerEnum.Onboarding: return "Onboarding on another project";
        case JoinProjectMemberBlockerEnum.JoiningCurrentProject: return "Already joining this project";
        case JoinProjectMemberBlockerEnum.LeavingAnyProject: return "Already leaving a project";
        case JoinProjectMemberBlockerEnum.Offboarding: return "Currently offboarding";
        case JoinProjectMemberBlockerEnum.OutsideProjectDomain: return "Outside project domain";
        case JoinProjectMemberBlockerEnum.InFlightAdminAssignmentToCurrentProject: return "Admin elevation already in-flight";
        case JoinProjectMemberBlockerEnum.CreatedAsProjectAdmin: return "Project Admin";
        default: return "Unavailable";
    }
};

export {
    joinProjectMemberBlockerReason
};
