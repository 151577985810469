import { 
    DeviceTypeEnum, 
    EndDateModeEnum, 
    ITaskItem, 
    MemberTypeEnum, 
    RateCurrencyEnum, 
    RateTypeEnum, 
    SubscriptionTypeEnum 
} from "./shared";

export enum ProjectTeamMemberStateEnum {
    Indeterminate = "Indeterminate",   
    AdminAssignment = "AdminAssignment",
    Onboarding = "Onboarding",
    Member = "Member",
    Joining = "Joining",
    Leaving = "Leaving",
    Offboarding = "Offboarding",
    RevokingAdminRights = "RevokingAdminRights",
}

export enum InferredProjectTeamMemberStatusEnum {
    Indeterminate = "Indeterminate",
    Processing = "Processing",
    Completed = "Completed",
    Failed = "Failed",
}

export interface ITeamAssignmentRequest {
    ProjectName: string;
    MemberType: MemberTypeEnum;
    FirstName: string;
    LastName: string;
    ContactEmailAddress: string;
    Country: string;
    CountryIso: string;
    StartDate: string;
    EndDate: string | undefined;
    EndDateMode: EndDateModeEnum;
    DeviceType: DeviceTypeEnum;
    Team: string | undefined;
    ProjectUpnDomain: string;
}

export interface ITeamAssignmentResponse {
    ProjectSlug: string;
    id: string;
    DateCreatedUtc: string;
    BoardId: string;
    ProjectName: string;
    MemberType: MemberTypeEnum;
    FirstName: string;
    LastName: string;
    ContactEmailAddress: string;
    Country: string;
    CountryIso: string;
    SubscriptionType: SubscriptionTypeEnum;
    StartDate: string;
    EndDate?: string;
    EndDateMode: EndDateModeEnum;
    Rate: number;
    RateType: RateTypeEnum;
    RateCurrency: RateCurrencyEnum;
    Status: string; // TODO: Change it to enum types. Find statuses from the state machine doc
    StatusDateTimeUtc: string;
    TaskItems: ITaskItem[];
    DeviceType: DeviceTypeEnum;
    CreatedBy: string;
    CreatedByName: string;
    Team: string;
    ProjectUpnDomain: string;
    AttestationTarget: string;
    WaveVersionNumber: string;
}

export enum CreateProjectTeamAssignmentDomainValidationFailureReasonEnum {
    Success = 0,
    ProjectNotFound = 1 << 0, // 1
    AuthenticatedUserDoesNotHavePermission = 1 << 1, // 2
    ContactEmailDomainDoesNotMatchProjectDomain = 1 << 2, // 4
    InFlightProjectAdminAssignments = 1 << 3, // 8
    DomainNotFound = 1 << 4, // 16
    InvalidDeviceType = 1 << 5, // 32
    ContactEmailAddressInUse = 1 << 6, // 64
    UserPrincipalNameInUse = 1 << 7 // 128
}

export interface IValidateProjectTeamAssignmentRequest {
    FirstName: string;
    LastName: string;
    ContactEmailAddress: string;
}

export interface IProjectTeamAssignmentErrorResponse {
    ValidationResult: CreateProjectTeamAssignmentDomainValidationFailureReasonEnum;
}
