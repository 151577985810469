import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Button, Tick, Checkbox, FormErrorText, FormControl } from "@breakingwave/react-ui-components";
import { useProjectPageContext } from "../../../../_state/context";
import { ProjectPageActionTypesEnum } from "../../../../_state/actions";
import useGetProjectTeamMemberDisplayItems from "../../../../../../../api/getProjectTeamMemberDisplayItems";
import { ConfirmationStepInputs, confirmationStepSchema } from "../index.schema";
import useRevokeProjectAdminRights from "../../../../../../../api/createRevokeProjectAdminRights";
import { IRevokeProjectAdminRightsErrorResponse } from "../../../../../../../types/revokeProjectAdminRights";
import { revokeProjectAdminRightsValidationFailureReason } from "../../../../../../../utils/revokeProjectAdminRightsValidationFailureReason";
import { IProjectTeamMemberDisplayItemsResponse } from "../../../../../../../types/teamMemberDisplayItems";
import { SubscriptionTypeEnum } from "../../../../../../../types/shared";
import * as S from "../../wizard.styles";

const Confirmation: React.FC = () => {

    const {
        state: projectPageState,
        state: {
            selectedProject: {
                ProjectSlug 
            }
        },
        dispatch: projectPageDispatch
    } = useProjectPageContext();

    const teamMember = projectPageState.revokeProjectAdminRightsWizard.tempRevokeProjectAdminRightsStepsData as IProjectTeamMemberDisplayItemsResponse;

    const { 
        refetch: refetchTeamMemberDisplayItems 
    } = useGetProjectTeamMemberDisplayItems(ProjectSlug);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,        
        watch
    } = useForm<ConfirmationStepInputs>({
        resolver: yupResolver(confirmationStepSchema)
    });

    const isConfirmed: boolean = watch("confirmation") ?? false;

    useEffect(() => {
        clearErrors();
    },[isConfirmed]);

    const { 
        mutateAsync: RevokeProjectAdminRights, 
        isSuccess: isSubmitRevokeSuccess,
        isLoading,
        data: RevokeProjectAdminRightsResponse
    } = useRevokeProjectAdminRights(
        ProjectSlug
    );

    const closeRevokeProjectAdminRightsWizard = (): void => {
        projectPageDispatch({
            type: ProjectPageActionTypesEnum.SET_REVOKE_ADMIN_RIGHTS_WIZARD,
            payload: {
                isCompleted: true,
                isActive: false,
                tempRevokeProjectAdminRightsStepsData: undefined,
                lastSavedRevokeUPN: RevokeProjectAdminRightsResponse?.UserPrincipalName
            }
        });
    };

    React.useEffect(() => {
        if (isSubmitRevokeSuccess) {
            refetchTeamMemberDisplayItems();
            closeRevokeProjectAdminRightsWizard();
        }
    }, [isSubmitRevokeSuccess]);

    const onConfirm = async (data: ConfirmationStepInputs): Promise<void> => {
        if (data.confirmation) {
            await RevokeProjectAdminRights({
                UserPrincipalName: projectPageState.revokeProjectAdminRightsWizard.tempRevokeProjectAdminRightsStepsData?.UserPrincipalName ?? ""
            }).catch((error) => {
                
                const result = error.response.data as IRevokeProjectAdminRightsErrorResponse;
                const bitwise: number = result.ValidationResult;                

                setError("confirmation", { 
                    message: revokeProjectAdminRightsValidationFailureReason(bitwise)
                });              
            });
        }
    };

    return (
        <>
            <S.QuestionWrapper style={{ marginBottom: 0 }}>
                <Text size={14} weight={300}>
                    You would like to revoke the project admin rights from the member.
                </Text>
                
                {teamMember?.SubscriptionType === SubscriptionTypeEnum.None && 
                    <Text size={12} weight={300} style={{ fontStyle: "italic" }}>
                        * The user account will be deleted if they are no longer a project admin in Wave.
                    </Text>
                }
                
                <Text size={14} weight={300} style={{ marginBottom: 12 }}>
                    This process will begin immediately.
                </Text>                
                <FormControl as="fieldset">
                    <Checkbox {...register("confirmation")} error={!!errors.confirmation} disabled={isLoading || isSubmitRevokeSuccess}>
                        Please check this box if you are happy to proceed
                    </Checkbox>
                    {(errors.confirmation) && (
                        <FormErrorText style={{ marginTop: 4 }}>{errors.confirmation.message}</FormErrorText>
                    )}
                </FormControl>
            </S.QuestionWrapper>            
            <S.OptionsWrapper>
                <Button variant="secondary" disabled={isLoading || isSubmitRevokeSuccess} onClick={closeRevokeProjectAdminRightsWizard}>
                    Cancel
                </Button>
                <Button
                    iconSvg={<Tick />}
                    iconSize={9}
                    isLoading={isLoading}
                    loadingIconSize={11}
                    onClick={handleSubmit(onConfirm)}
                    disabled={!isConfirmed || isSubmitRevokeSuccess}
                >
                    Confirm
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default Confirmation;
