import { 
    EndDateModeEnum,
    RateCurrencyEnum, 
    RateTypeEnum, 
    MemberTypeEnum, 
    SubscriptionTypeEnum, 
    DeviceTypeEnum 
} from "../../../../../../../types/shared";

export enum JoinProjectWizardStepIdentifierEnum {
    MemberType = "memberType",
    SearchedMemberData = "searchedMemberData",
    Confirmation = "confirmation"
}

export interface IJoinProjectWizardStepsState {
    [JoinProjectWizardStepIdentifierEnum.MemberType]: {
        order: undefined;
        isStepAnswered: undefined;
        isStepActive: undefined;
        answer: MemberTypeEnum;
    };
    [JoinProjectWizardStepIdentifierEnum.SearchedMemberData]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
        answer: {
            upn: string;
            searchMemberLabel: string;
            searchMemberValue: string;
            team: string;
            personalDetails: {
                firstName: string;
                lastName: string;
                email: string;
                displayValue: string;
            };
            country: {
                country: string; // Country name
                isoString: string; // alpha-2 country code
                displayValue: string;
            };
            deviceType: {
                deviceType: DeviceTypeEnum | undefined;
            };
            startDate: {
                isoString: string;
                displayValue: string;
            };
            subscriptionType: {
                subscriptionType: SubscriptionTypeEnum | null;
                displayValue: string;
            };
            rate: {
                rate: number;
                rateType: RateTypeEnum | null;
                rateCurrency: RateCurrencyEnum | null;
                displayValue: string;
            };
            endDate: {
                endDateMode: EndDateModeEnum | undefined;
                isoString: string | undefined;
                displayValue: string | undefined;
            };
            homeProject: {
                displayValue: string | undefined;
            };
        };
    };
    [JoinProjectWizardStepIdentifierEnum.Confirmation]: {
        order: number;
        isStepAnswered: boolean;
        isStepActive: boolean;
        answer: {
            confirmation: boolean;
        };
    };
}

/**
 * This special type is constructed out of IJoinProjectWizardStepsState interface.
 * For the "Join Project Wizard" reducer function work, each state defined in the context provider must have an "order" key. Since MemberType state entry isn't part of wizard steps but still exist in the wizard flow, we needed to omit them to prevent TypeScript errors.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export type IJoinProjectWizardStepState__UndefinedFiltered = Omit<
IJoinProjectWizardStepsState,
JoinProjectWizardStepIdentifierEnum.MemberType
>;
