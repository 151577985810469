import React from "react";
import dayjs from "dayjs";
import { Text } from "@breakingwave/react-ui-components";
import TeamAccordion from "../../../../../../components/accordion/TeamAccordion";
import { IProjectTeamMemberDisplayItemsResponse } from "../../../../../../types/teamMemberDisplayItems";
import { wizardColours } from "../wizard.colours";
import formatRateDisplay from "../../../../../../utils/formatRateDisplay";
import { getTeamName } from "../../../../../../utils/teams";
import useUserType from "../../../../../../hooks/useUserType";
import { LeaveProjectWizardStepsContext, reducer } from "./_state/context";
import initialLeaveProjectWizardStepsState from "./_state/initialState";
import LeaveProjectWizardSteps from "./steps";
import * as S from "../wizard.styles";

interface ILeaveProjectWizardStepsProps {
    data: IProjectTeamMemberDisplayItemsResponse;
}

/**
 * LeaveProject mode of <TeamWizards />.
 * @param {IProjectTeamMemberDisplayItemsResponse} data - Single team member data.
 */
const LeaveProjectWizard: React.FC<ILeaveProjectWizardStepsProps> = ({ data: teamMember }) => {
    const [state, dispatch] = React.useReducer(reducer, initialLeaveProjectWizardStepsState);

    React.useEffect(() => {
        if (process.env.REACT_APP_ENV_TYPE === "local") {
            // ---- console log leave project wizard steps state only in dev env ---- //
            console.log("Leave project wizard steps state: ", state);
        }
    }, [state]);

    const { userType } = useUserType(teamMember);
    
    return (
        <LeaveProjectWizardStepsContext.Provider value={{ state, dispatch }}>
            <TeamAccordion
                profileProgressCircleProps={{
                    size: 30,
                    percentage: 100,
                    userType,
                    percentagePathColour: wizardColours.memberIcon
                }}
                isToggleable={false}
                hasToggleIcon={false}
                homeProjectName={teamMember.HomeProjectName}
                teamMemberDisplayName={`${teamMember.FirstName} ${teamMember.LastName}`}
                team={getTeamName(teamMember.Team)}
                country={teamMember.Country}
                deviceType={teamMember.DeviceType}
                startDate={dayjs(teamMember.StartDate).format("DD MMM YYYY")}
                rate={`${formatRateDisplay(teamMember.Rate, teamMember.RateCurrency)}pcm`}
                defaultOpenState={true}
            >
                <S.TeamAccordionBody>
                    <S.StepLabel>
                        <Text variant="span" size={14} weight={500}>
                            Remove team member (leave)
                        </Text>
                    </S.StepLabel>
                    <LeaveProjectWizardSteps />
                </S.TeamAccordionBody>
            </TeamAccordion>
        </LeaveProjectWizardStepsContext.Provider>
    );
};

export default LeaveProjectWizard;
