import styled, { css } from "styled-components";
import { AccordionToggleIconTypeEnum } from "../../types/accordions";
import ContextMenu from "../context-menu";

const AccordionWrapper = styled.div`
    height: auto;
    border: 1px solid #595959;
    background-color: #242424;
    border-radius: 4px;
    margin-bottom: 8px;
`;

const ToggleIcon = styled.div<{
    toggleIconType: AccordionToggleIconTypeEnum | undefined;
}>`
    ${({ toggleIconType }) => {
        if (toggleIconType === AccordionToggleIconTypeEnum.Chevron) {
            return css`
                background: url(${process.env
        .REACT_APP_IMAGE_ASSETS_CDN}/icons/chevron_down_darkgray_round_border_16.svg);
            `;
        } else if (AccordionToggleIconTypeEnum.Threedots) {
            return css`
                background: url(${process.env.REACT_APP_IMAGE_ASSETS_CDN}/icons/threedots_darkgray_round_border.svg);
            `;
        } else return null;
    }};
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    user-select: none;
    cursor: pointer;
`;

const AccordionHeader = styled.div<{ isToggleable: boolean; isOpen: boolean }>`
    position: relative;
    display: grid;
    grid-template-columns: 45px 160px 205px 190px 190px 190px 155px 112px 18px;
    height: 50px;
    align-items: center;
    padding-left: 14px;
    border-radius: 4px;
    background-color: #1d1d1d;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    &:hover {
        background-color: #2a2a2a;
    }

    ${({ isOpen }) =>
        isOpen &&
        css`
            background-color: #2a2a2a;
        `};

    ${({ isOpen, isToggleable }) =>
        isOpen &&
        isToggleable &&
        css`
            cursor: pointer;

            & > ${ToggleIcon} {
                transform: rotate(180deg);
            }
        `};
`;

const GenericRequestAccordionHeader = styled(AccordionHeader)`
    grid-template-columns: 45px 330px 375px 360px 130px 25px;
`;

const ToggledContextMenu = styled(ContextMenu)`
    border-color: transparent;
    color: white;
    background-color: #4D4D4D;
    right: 10px;
    margin-top: 7px;
    z-index: 99;

    &:after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: #4D4D4D transparent;
        border-width: 0 6px 6px;
        top: -7px;
        left: 88%;
    }

    & > div:first-child {
        border-radius: 5px 5px 0px 0px !important;
    }

    & > div:last-child {
        border-radius: 0px 0px 5px 5px !important;
    }    

    & > div {
        border-color: transparent;
        padding: 6px 11px;
        font-size: 12px;        

        &:hover {
            color: #1d1d1d;
            background-color: #D4C9F1;
            text-decoration: none;
        }
    }
`;

const AccordionClickableLink = styled.span`
    cursor: pointer;
    text-decoration: underline;
    color: #46e1ff;

    &:hover {
        color: #3aa7bc;
    }
`;

export { 
    AccordionWrapper, 
    AccordionHeader, 
    GenericRequestAccordionHeader,
    ToggleIcon, 
    ToggledContextMenu,
    AccordionClickableLink
};
