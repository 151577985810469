import React from "react";
import dayjs from "dayjs";
import { Text } from "@breakingwave/react-ui-components";
import TeamAccordion from "../../../../../../components/accordion/TeamAccordion";
import { IProjectTeamMemberDisplayItemsResponse } from "../../../../../../types/teamMemberDisplayItems";
import { wizardColours } from "../wizard.colours";
import formatRateDisplay from "../../../../../../utils/formatRateDisplay";
import { getTeamName } from "../../../../../../utils/teams";
import useUserType from "../../../../../../hooks/useUserType";
import { OffboardTeamMemberWizardStepsContext, reducer } from "./_state/context";
import initialOffboardTeamMemberWizardStepsState from "./_state/initialState";
import OffboardTeamMemberWizardSteps from "./steps";
import * as S from "../wizard.styles";

interface IOffboardTeamMemberWizardStepsProps {
    data: IProjectTeamMemberDisplayItemsResponse;
}

/**
 * OffboardTeamMember mode of <TeamWizards />.
 * @param {IProjectTeamMemberDisplayItemsResponse} data - Single team member data.
 */
const OffboardTeamMemberWizard: React.FC<IOffboardTeamMemberWizardStepsProps> = ({ data: teamMember }) => {
    const [state, dispatch] = React.useReducer(reducer, initialOffboardTeamMemberWizardStepsState);

    React.useEffect(() => {
        if (process.env.REACT_APP_ENV_TYPE === "local") {
            // ---- console log offboard team member wizard steps state only in dev env ---- //
            console.log("Offboard team member wizard steps state: ", state);
        }
    }, [state]);

    const { userType } = useUserType(teamMember);
    
    return (
        <OffboardTeamMemberWizardStepsContext.Provider value={{ state, dispatch }}>
            <TeamAccordion
                profileProgressCircleProps={{
                    size: 30,
                    percentage: 100,
                    userType,
                    percentagePathColour: wizardColours.memberIcon
                }}
                isToggleable={false}
                hasToggleIcon={false}
                homeProjectName={teamMember.HomeProjectName}
                teamMemberDisplayName={`${teamMember.FirstName} ${teamMember.LastName}`}
                team={getTeamName(teamMember.Team)}
                country={teamMember.Country}
                deviceType={teamMember.DeviceType}
                startDate={dayjs(teamMember.StartDate).format("DD MMM YYYY")}
                rate={`${formatRateDisplay(teamMember.Rate, teamMember.RateCurrency)}pcm`}
                defaultOpenState={true}
            >
                <S.TeamAccordionBody>
                    <S.StepLabel>
                        <Text variant="span" size={14} weight={500}>
                            Remove team member (off-board)
                        </Text>
                    </S.StepLabel>
                    <OffboardTeamMemberWizardSteps />
                </S.TeamAccordionBody>
            </TeamAccordion>
        </OffboardTeamMemberWizardStepsContext.Provider>
    );
};

export default OffboardTeamMemberWizard;
