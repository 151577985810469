import { CurrencySymbolsEnum, RateCurrencyEnum } from "../types/shared";

/**
 * Receives a "rate" and the name of a "currency" and returns the matching currency symbol concatenated with the rate value.
 * @param {number} rate - Numeric rate value. The value can be integer or decimal. The decimal part gets truncated for display.
 * @param {RateCurrencyEnum} rateCurrency - Short name of the currency. One of RateCurrencyEnum type.
 * @returns formatted rate for display. E.g: €40 or £33
 */
const formatRateDisplay = (rate: number, rateCurrency: RateCurrencyEnum): string =>
    rate >= 0 && CurrencySymbolsEnum[rateCurrency] ? CurrencySymbolsEnum[rateCurrency] + parseFloat(rate.toFixed(2)) : "N/A";

export default formatRateDisplay;
