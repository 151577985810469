import React from "react";
import dayjs from "dayjs";
import { IProjectTeamMemberDisplayItemsResponse } from "../../../../../types/teamMemberDisplayItems";
import useUserType from "../../../../../hooks/useUserType";
import TeamAccordion from "../../../../../components/accordion/TeamAccordion";
import { getTeamName } from "../../../../../utils/teams";
import formatRateDisplay from "../../../../../utils/formatRateDisplay";
import { indeterminateTeamMemberColours } from "./index.colours";

interface IIndeterminateTeamMemberProps {
    data: IProjectTeamMemberDisplayItemsResponse;
}

const IndeterminateTeamMember: React.FC<IIndeterminateTeamMemberProps> = ({
    data: teamMember
}) => {
    const { userType } = useUserType(teamMember);

    return (
        <TeamAccordion
            profileProgressCircleProps={{
                size: 30,
                percentage: 100,
                userType,
                percentagePathColour: indeterminateTeamMemberColours.memberIcon
            }}
            isToggleable={false}
            hasToggleIcon={false}
            homeProjectName={teamMember.HomeProjectName}
            teamMemberDisplayName={`${teamMember.FirstName} ${teamMember.LastName}`}
            country={teamMember.Country}
            deviceType={teamMember.DeviceType}
            startDate={dayjs(teamMember.StartDate).format("DD MMM YYYY")}
            team={getTeamName(teamMember.Team)}
            rate={`${formatRateDisplay(teamMember.Rate, teamMember.RateCurrency)}pcm`}
            defaultOpenState={false}
        >
        </TeamAccordion>
    );
};

export default IndeterminateTeamMember;
