import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Checkbox, Text, Tick } from "@breakingwave/react-ui-components";
import { useTeamAssignmentStepsContext } from "../_state/context";
import { TeamAssignmentWizardStepsActionTypesEnum } from "../_state/actions";
import { TeamAssignmentWizardStepIdentifierEnum } from "../_state/types";
import { EndDateModeEnum, SubscriptionTypeEnum } from "../../../../../../../types/shared";
import formatRateDisplay from "../../../../../../../utils/formatRateDisplay";
import { SubscriptionTypeStepInputs, subscriptionTypeStepSchema } from "../index.schema";
import { useProjectPageContext } from "../../../../_state/context";
import * as S from "../../wizard.styles";

const SubscriptionType: React.FC = () => {

    const { 
        state: {
            selectedProject
        } 
    } = useProjectPageContext();
 
    const { state, dispatch } = useTeamAssignmentStepsContext();

    // Fully typed, shorter spelling of the step name for easy access
    const step = state[TeamAssignmentWizardStepIdentifierEnum.SubscriptionType];

    const { register, handleSubmit } = useForm<SubscriptionTypeStepInputs>({
        resolver: yupResolver(subscriptionTypeStepSchema),
        defaultValues: { subscriptionType: true }
    });

    const processAndGoToNext = (): void => {
        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.SET_SUBSCRIPTION_TYPE,
            payload: {
                ...step,
                answer: {
                    ...step.answer,
                    subscriptionType: SubscriptionTypeEnum.Wave,
                    displayValue: "Wave subscription"
                }
            }
        });

        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.SET_DAILY_RATE,
            payload: {
                ...state[TeamAssignmentWizardStepIdentifierEnum.Rate],

                answer: {
                    ...state[TeamAssignmentWizardStepIdentifierEnum.Rate].answer,
                    rate: selectedProject.Rate,
                    displayValue: `${formatRateDisplay(selectedProject.Rate, selectedProject.RateCurrency)}pcm`
                }
            }
        });

        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.SET_END_DATE,
            payload: {
                ...state[TeamAssignmentWizardStepIdentifierEnum.EndDate],
                answer: {
                    ...state[TeamAssignmentWizardStepIdentifierEnum.EndDate].answer,
                    endDateMode: EndDateModeEnum.MonthlyRolling,
                    displayValue: undefined,
                    isoString: undefined
                }
            }
        });

        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP
        });
    };

    const goBack = (): void => {
        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_PREV_STEP
        });
    };

    return (
        <>
            <S.QuestionWrapper>
                <Text size={14} weight={500}>
                    Subscription type
                </Text>
            </S.QuestionWrapper>
            <S.AnswerWrapper>
                <S.SubscriptionTypeContainer>
                    <Checkbox {...register("subscriptionType")} variant="withBorder" disabled>
                        Wave subscription (€624pcm)
                    </Checkbox>
                    <Text size={12} color="white.50" style={{ marginTop: 8 }}>
                        * This subscription is billed monthly.
                    </Text>
                </S.SubscriptionTypeContainer>
            </S.AnswerWrapper>
            <S.OptionsWrapper>
                <Button variant="secondary" onClick={goBack}>
                    Back
                </Button>
                <Button iconSvg={<Tick />} iconSize={9} onClick={handleSubmit(processAndGoToNext)}>
                    Next
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default SubscriptionType;
