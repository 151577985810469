import React from "react";
import dayjs from "dayjs";
import TeamAccordion from "../../../../../../components/accordion/TeamAccordion";
import { wizardColours } from "../wizard.colours";
import { UserTypeEnum } from "../../../../../../types/shared";
import StepIndicatorCircle from "../../../../../../components/step-indicator-circle";
import { useProjectPageContext } from "../../../_state/context";
import formatRateDisplay from "../../../../../../utils/formatRateDisplay";
import { getTeamName } from "../../../../../../utils/teams";
import { ProjectAdminAssignmentWizardStepIdentifierEnum } from "./_state/types";
import initialProjectAdminAssignmentStepsState from "./_state/initialState";
import { ProjectAdminAssignmentStepsContext, reducer } from "./_state/context";
import ProjectAdminAssignmentWizardSteps from "./steps";
import * as S from "../wizard.styles";

/**
 * ProjectAdminAssignmentWizard mode of <TeamWizards />.
 */
const ProjectAdminAssignmentWizard: React.FC = () => {

    const { 
        state: {
            projectAdminAssignmentWizard: {
                tempProjectAdminAssignmentStepsData: pageLevelStepsData                
            }
        }
    } = useProjectPageContext();

    const [state, dispatch] = React.useReducer(reducer, initialProjectAdminAssignmentStepsState);

    const percentage = (): number => {
        const isAnsweredArray = Object.values(state)
            .filter((step) => step.order !== undefined)
            .map((step) => step.isStepAnswered);
        return (isAnsweredArray.filter((val) => val).length / isAnsweredArray.length) * 100;
    };

    React.useEffect(() => {
        if (process.env.REACT_APP_ENV_TYPE === "local") {
            console.log("Add project admin assignment steps state: ", state);
        }
    }, [state]);

    return (
        <ProjectAdminAssignmentStepsContext.Provider value={{ state, dispatch }}>
            <TeamAccordion
                profileProgressCircleProps={{
                    size: 30,
                    percentage: percentage(),
                    percentagePathColour: wizardColours.memberIcon,
                    userType: (state[ProjectAdminAssignmentWizardStepIdentifierEnum.ProjectAdminName].elevateMode ? UserTypeEnum.UserAndProjectAdmin : UserTypeEnum.ProjectAdmin)
                }}
                isToggleable={false}
                hasToggleIcon={false}
                defaultOpenState={true}
                homeProjectName="-"
                teamMemberDisplayName={state[ProjectAdminAssignmentWizardStepIdentifierEnum.ProjectAdminName].answer.displayValue}
                team={pageLevelStepsData?.Team ? getTeamName(pageLevelStepsData?.Team) : "-"}
                country={pageLevelStepsData?.Country ?? "N/A"}
                deviceType={pageLevelStepsData?.DeviceType ?? undefined}
                startDate={pageLevelStepsData?.StartDate ? dayjs(pageLevelStepsData?.StartDate).format("DD MMM YYYY") : "N/A"}
                rate={pageLevelStepsData?.Rate ? `${formatRateDisplay(pageLevelStepsData?.Rate, pageLevelStepsData?.RateCurrency)}pcm` : "N/A"}
            >
                <S.TeamAccordionBody>
                    <S.StepIndicatorCirclesWrapper>
                        {Object.values(state)
                            .filter((step) => step.order !== undefined)
                            .sort((a, b) => a.order - b.order)
                            .map((step, index) => (
                                <StepIndicatorCircle
                                    key={index}
                                    value={index + 1}
                                    active={step.isStepActive}
                                    completed={!step.isStepActive && step.isStepAnswered}
                                    disabled={!step.isStepActive && !step.isStepAnswered}
                                    style={{ marginRight: 8 }}
                                />
                            ))}
                    </S.StepIndicatorCirclesWrapper>
                    <ProjectAdminAssignmentWizardSteps />
                </S.TeamAccordionBody>
            </TeamAccordion>
        </ProjectAdminAssignmentStepsContext.Provider>
    );
};

export default ProjectAdminAssignmentWizard;
